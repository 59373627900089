import React from "react";
import ReactDOM from "react-dom";
import Main from "./Components/Main/Main";
import register from "./registerServiceWorker";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "./styles/css/style.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#00a7d6",
			contrastText: "#FFFFFF",
		},
	},
	overrides: {
		MuiButton: {
			contained: {
				backgroundColor: "#00a7d6",
				color: "#FFFFFF",
			},
		},
	},
});
console.info(`%c User Agent: ${navigator.userAgent}`, "color: grey;");
ReactDOM.render(
	<ThemeProvider theme={theme}>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Main />
		</MuiPickersUtilsProvider>
	</ThemeProvider>,
	document.getElementById("root")
);
register();
