import React from "react";
import { Route, Redirect } from "react-router-dom";
import _ from "lodash";
import { getAuthData } from "../Services/StorageService";
import { logout } from "../State/actions/user";
import { connect } from "react-redux";
import {
	STATION_SATCOM_LANDING,
	THURAYA_LANDING,
	VOYAGER_LANDING,
	VIKAND_LANDING,
	INTELL4DESK_LANDING,
	AAGEHEMPEL_LANDING,
	LANDING_PATH_LIST,
	VIKAND_DIRECT_LANDING,
	BEACONASSIST_LANDING,
} from "../Utils/Constants";

const NON_PROTECTED_PATH = [
	...LANDING_PATH_LIST,
	"/resetPassword",
	"/sendcode",
	"/resetConfirmPassword",
	"/verify",
];

const getDestinationPath = (props) => {
	if (!props) {
		return "/login";
	}
	if (
		props.path === THURAYA_LANDING ||
		props.path === BEACONASSIST_LANDING ||
		props.path === VOYAGER_LANDING ||
		props.path === VIKAND_LANDING ||
		props.path === VIKAND_DIRECT_LANDING ||
		props.path === INTELL4DESK_LANDING ||
		props.path === STATION_SATCOM_LANDING ||
		props.path === AAGEHEMPEL_LANDING
	) {
		return props.path;
	}
	return "/login";
};
const ProtectedRoute = ({ component: Component, ...rest }) => {
	if (isValidURL(rest)) {
		if (NON_PROTECTED_PATH.includes(rest.path)) {
			const authData = getAuthData();
			if (!_.isEmpty(authData)) {
				return <Redirect to="/app/home" />;
			}
		}
		return <Route render={() => <Component {...rest} />} {...rest} />;
	} else {
		const destinationPath = getDestinationPath(rest);
		return <Redirect from={rest.path} to={destinationPath} />;
	}
};

const isValidURL = (props) => {
	const authData = getAuthData();
	let isAnonymousUser = !_.isEmpty(authData) ? authData.isAnonymousUser : false;
	if (!isAnonymousUser) {
		return true;
	}
	props.logout();
	return false;
};

const mapActionToProps = {
	logout,
};
export default connect(null, mapActionToProps)(ProtectedRoute);
