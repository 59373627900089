import React, { Component } from "react";
import { ONSHIP_LANDING } from "../../Utils/Constants";

class GoogleLoginButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	startLogin = () => {
		this.props.clearLoginError();
		window.auth2
			.grantOfflineAccess({
				scope: "profile email",
			})
			.then(this.onSignIn);
	};

	onSignIn = (code) => {
		// const auth2 = window.auth2;
		// let authUser = auth2.currentUser.get();
		// let profile = authUser.getBasicProfile();
		// let user = {
		//   emailAddress: profile.getEmail(),
		//   givenName: profile.getGivenName(),
		//   screenName: profile.getName() ? profile.getName().replace(/ /g, "") : "",
		//   surname: profile.getFamilyName() || profile.getSurname(),
		//   userName: profile.getName(),
		//   userId: profile.getId()
		// };
		// let user = {};
		this.props.doGoogleAuth(code.code, this.props.onLogin);
	};

	render() {
		let landingPath = this.props.landingPath;
		return (
			<div>
				{landingPath === ONSHIP_LANDING ? (
					<a
						onClick={this.startLogin}
						className="d-block rounded si-colored-g-plus mb-2"
					>
						<img
							src="/img/GoogleButtonWideWidth.png"
							alt=""
							className="socialLogin-btn-wide"
							// style={{ width: "250px", height: "40px" }}
						/>
					</a>
				) : (
					<a
						onClick={this.startLogin}
						className="d-block rounded si-colored-g-plus mb-2"
					>
						<img
							src="/img/btn-google@2x.png"
							alt=""
							className="socialLogin-btn"
							// style={{ width: "250px", height: "40px" }}
						/>
					</a>
				)}
			</div>
		);
	}
}

export default GoogleLoginButton;
// w - 255 , h - 33
