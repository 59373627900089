"use strict";

export class Messages {
	removeMessages = (botKey) => {
		return Promise(function (resolve, reject) {
			// resolve(true);
			reject("This feature is not supported for web requests.");
		});
	};
}
